<template>
  <div>
    <div v-if="isShow == 0">
      <div>
      </div>
      <div>
        <el-row style="margin: 20px">
          <el-col :span="9">
            <span>创建日期：</span>
            <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-input placeholder="输入卡号/手机号/地址" v-model="query.search"></el-input>
          </el-col>

          <el-col :span="7">
            <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
              <div style="display: flex; align-items: center; font-size: 14px">
                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                <span style="margin-left: 3px">查询</span>
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>
      <el-row style="padding: 10px 20px; border-bottom: 1px solid #eee">
        <el-col :span="2">
          <el-button type="primary" size="mini" @click="exportClick">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">导出团队</span>
            </div>
          </el-button>
        </el-col>
      </el-row>

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="card_number" sortable label="卡号"></el-table-column>
          <el-table-column prop="member_name" label="姓名"></el-table-column>
          <el-table-column prop="bind_phone" label="手机"></el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
          <el-table-column prop="card_type" label="类型"></el-table-column>
          <el-table-column prop="level" label="等级"></el-table-column>
          <el-table-column prop="card_balance" sortable label="余额"></el-table-column>
          <el-table-column prop="card_gold" sortable label="金币"></el-table-column>
          <el-table-column prop="consumption" sortable label="累计消费数量" min-width="110"></el-table-column>
          <el-table-column prop="sum" sortable label="累计消费金额" min-width="110"></el-table-column>
          <el-table-column prop="recommend" sortable label="累计推荐数量" min-width="110"></el-table-column>
          <el-table-column prop="people_sum" sortable label="累计推荐人数" min-width="110"></el-table-column>
          <el-table-column prop="pay_sum" sortable label="累计推荐金额" min-width="110"></el-table-column>
          <el-table-column prop="referrer" label="推荐人" min-width="110"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="detail(scope.row)">
                详情
              </el-button>
              <el-button @click="junior(scope.row)">
                下级
              </el-button>
              <!-- <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')"
              @click="handleEdit(scope.$index, scope.row)" />
            <span style="margin: 0 10px"></span>
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')"
              @click="handleDelete(scope.$index, scope.row)" /> -->
            </template>
          </el-table-column>
        </el-table>
        <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
          :total="total">
        </pagination>
      </div>
    </div>
    <info v-if="isShow == 1" @close="onClose" :data="info"></info>
  </div>
</template>

<script>
import pagination from "@/components/pagination/index.vue";
import info from "../label3_memberInformation/childrens/memberInfo.vue"
export default {
  components: {
    pagination, info
  },
  data() {
    return {
      isShow: 0,
      info: {},
      t1: [],
      total: 0,
      tableData: [],
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      query: {
        commercial_id: localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id"),
        firstTime: "",
        lastTime: "",
        currentPage: 1,
        currentLength: 20,
        search: "",
      },

      searchData: {
        date: "",
        selectPlace1: "卡等级",
        option1: "",
        option1es: [],

        searchPlace: "输入卡号/手机号/地址",
        QueryContent: "",
        searchBtn1: true,
      },


      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    this.handleQuery();
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  onClose() {
    this.isShow = 0
  },
  computed: {},
  methods: {
    async exportClick() {
      console.log('导出操作');
    },
    async junior({ member_id }) {
      let query = this.query;
      query.member_id = member_id;
      let res = (await this.$http.post("/client/promoter/subordinate", query)).data;
      this.total = res.count;
      this.tableData = res.data;

    },
    detail(row) {
      this.info = row
      this.isShow = 1;


    },
    reload() {
      location.reload();
    },
    onClose() {
      this.isShow = 0;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit() { },
    handleDelete() { },
    async handleQuery(page = 1) {
      let query = this.query;
      query.currentPage = page;
      let res = (await this.$http.post("/client/promoter/team_lst", query)).data;
      this.total = res.count;
      this.tableData = res.data;
    }
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
